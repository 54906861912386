/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
/* eslint-disable arrow-body-style */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Layout } from '../components/common';
import Banner from '../images/policy/Banner.svg';
import styles from './styles/policy.module.scss';
import newStyles from './styles/newPolicy.module.scss';
import SEO from '../components/common/SEO';
import { useViewport } from '../hooks';

export default function Policy() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='Privacy Policy - Witbe'>
            <SEO
                title='Privacy Policy - Witbe'
                ogDescription='Trust and clarity are essential to us at Witbe. Understand what data is affected by Witbe’s privacy policy'
                description='Trust and clarity are essential to us at Witbe. Understand what data is affected by Witbe’s privacy policy'
                image={Banner}
            />
            {/* <div id={newStyles.mainImageWrapper}>
                <div className={newStyles.mainImageTextWrapper}>
                    <div className={newStyles.mainImageLeft}>
                        <div className={newStyles.mainImageContent}>
                            <h1 className={newStyles.mainImageTitle}>
                                {t('siteMetadata.policy.title')}
                            </h1>
                        </div>
                    </div>
                </div>
            </div> */}
            <div id={newStyles.articlesContainer}>
                <div className={newStyles.articlesMain}>
                    <div className={newStyles.articlesContainer}>
                        <section id={newStyles.whiteSection}>
                            <h1 className={newStyles.mainImageTitle}>
                                {t('siteMetadata.policy2024.title')}
                            </h1>
                            <div className={newStyles.whiteSectionDate}>
                                <p>{t('siteMetadata.policy2024.date')}</p>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p>{t('siteMetadata.policy2024.intro')}</p>
                            </div>
                            <div id='legal-mentions'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.legalMentions')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.legalMentionsSub`)}} />
                            </div>
                            <div id='intellectual-property'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.intellectualProperty')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.intellectualSub`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.intellectualSub2`)}} />
                            </div>
                            <div id='privacy-policy'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.privacy')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.privacySub`)}} />
                            </div>
                            <div id='what-data'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.whatData')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p>{t('siteMetadata.policy2024.whatData1')}</p>
                                <ol>
                                <li>{t('siteMetadata.policy2024.whatData2')}</li>
                                <p>{t('siteMetadata.policy2024.whatData3')}</p>
                                <p>{t('siteMetadata.policy2024.whatData4')}</p>
                                <li>{t('siteMetadata.policy2024.whatData5')}</li>
                                <p>{t('siteMetadata.policy2024.whatData6')}</p>
                            </ol>
                            </div>
                            <div id='how-data'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.how')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p>{t('siteMetadata.policy2024.howSub')}</p>
                                <ul>
                                <li>{t('siteMetadata.policy2024.howCollect1')}</li>
                                <li>{t('siteMetadata.policy2024.howCollect2')}</li>
                                <li>{t('siteMetadata.policy2024.howCollect3')}</li>
                                <li>{t('siteMetadata.policy2024.howCollect4')}</li>
                            </ul>
                            </div>
                            <div id='usage'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.how2')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p>{t('siteMetadata.policy2024.how2Sub')}</p>
                                <ul>
                                <li>{t('siteMetadata.policy2024.howUse1')}</li>
                                <li>{t('siteMetadata.policy2024.howUse2')}</li>
                            </ul>
                                <p>{t('siteMetadata.policy2024.howUse3')}</p>
                            </div>
                            <div id='storing-data'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.how3')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.how3Sub`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.how3Sub2`)}} />
                            </div>
                            <div id='marketing'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.marketing')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.marketing1`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.marketing2`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.marketing3`)}} />
                            </div>
                            <div id='your-rights'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.rights')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.rightsSub`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.rights1`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.rights2`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.rights3`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.rights4`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.rights5`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.rights6`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.rights7`)}} />
                            </div>
                            <div id='what-are-cookies'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.cookies')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.cookiesSub`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.cookiesSub2`)}} />
                            </div>
                            <div id='cookies-usage'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.cookiesHow')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p>{t('siteMetadata.policy2024.cookiesHowSub')}</p>
                                <ul>
                                <li>{t('siteMetadata.policy2024.cookiesHow1')}</li>
                                <li>{t('siteMetadata.policy2024.cookiesHow2')}</li>
                            </ul>
                            </div>
                            <div id='cookies-types'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.types')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p>{t('siteMetadata.policy2024.typesSub')}</p>
                                <ul>
                                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.types1`)}} />
                                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.types2`)}} />
                            </ul>
                            </div>
                            <div id='how-to-manage'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.manage')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.manageSub`)}} />
                            </div>
                            <div id='other-websites'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.other')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.otherSub`)}} />
                            </div>
                            <div id='changes'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.changes')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.changesSub`)}} />
                            </div>
                            <div id='contact'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.contact')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.contact1`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.contact2`)}} />
                            </div>
                            <div id='contact-authority'>
                                <h2 id={newStyles.whiteSectionSubtitle}>
                                    {t('siteMetadata.policy2024.authority')}
                                </h2>
                            </div>
                            <div className={newStyles.whiteSectionText}>
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.authority1`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.authority2`)}} />
                                <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.policy2024.authority3`)}} />
                            </div>
                        </section>
                    </div>
                </div>
                {width < breakpoint ?
                    null
                    :
                    (
                        <aside className={newStyles.sidebar}>
                            <div className={newStyles.listsContainer}>
                                <ul className={newStyles.sidebarList}>
                                    <h3 className={newStyles.sidebarHeader}>Topics</h3>
                                    <ul className={newStyles.sidebarSubList}>
                                        <li>
                                            <AnchorLink
                                                to='/policy#legal-mentions'
                                            >
                                                {t('siteMetadata.policy2024.topics.legal')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#intellectual-property'
                                            >
                                                {t('siteMetadata.policy2024.topics.property')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#privacy-policy'
                                            >
                                                {t('siteMetadata.policy2024.topics.privacyPolicy')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#what-data'
                                            >
                                                {t('siteMetadata.policy2024.topics.whatData')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#how-data'
                                            >
                                                {t('siteMetadata.policy2024.topics.collect')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#usage'
                                            >
                                                {t('siteMetadata.policy2024.topics.use')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#storing-data'
                                            >
                                                {t('siteMetadata.policy2024.topics.store')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#marketing'
                                            >
                                                {t('siteMetadata.policy2024.topics.marketing')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#your-rights'
                                            >
                                                {t('siteMetadata.policy2024.topics.rights')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#what-are-cookies'
                                            >
                                                {t('siteMetadata.policy2024.topics.cookies')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#cookies-usage'
                                            >
                                                {t('siteMetadata.policy2024.topics.howCookies')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#cookies-types'
                                            >
                                                {t('siteMetadata.policy2024.topics.types')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#how-to-manage'
                                            >
                                                {t('siteMetadata.policy2024.topics.manage')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#other-websites'
                                            >
                                                {t('siteMetadata.policy2024.topics.privacy')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#changes'
                                            >
                                                {t('siteMetadata.policy2024.topics.changes')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#contact'
                                            >
                                                {t('siteMetadata.policy2024.topics.contact')}
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                to='/policy#contact-authority'
                                            >
                                                {t('siteMetadata.policy2024.topics.authority')}
                                            </AnchorLink>
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                        </aside>
                    )}
            </div>
        </Layout>
    );
}
